import React from "react"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import WingedContent from "../components/Decorators/WingedContent"
import { StaticImage } from "gatsby-plugin-image"
import paths from "../constants/paths"
import pricing from "../constants/pricing"
import styled from "styled-components"

const Container = styled.div`
  section {
    padding-top: 1rem;
    padding-bottom: 0.25rem;

    h2, h3 {
      margin-top: 0;
    }
  }

  > .gatsby-image-wrapper {
    max-height: 95vh;
  }
`

const cheersAngleInDegrees = 5;
const PricingList = styled.section`
  background: var(--brand-color-off-white);
  z-index: 1;
  position: relative;
  width: 100%;
  overflow: hidden;

  h2 {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  p {
    text-align: center;
    padding-top: 0;
    margin-top: 0;
  }

  article {
    position: relative;
    text-align: center;
  }

  article {
    .leftWing, .rightWing {
      .gatsby-image-wrapper, img {
        height: 100px;
      }

      @media (min-width: 1200px) {
        .gatsby-image-wrapper, img {
          height: 160px;
        }
      }
    }
  }

  article:first-child {
    .rightWing {
      right: -70px;
      top: -10px;
      transform: rotate(-${cheersAngleInDegrees}deg);
  
      @media (min-width: 768px) {
        right: -140px;
        top: 0px;
      }
  
      @media (min-width: 1200px) {
        right: -190px;
      }
    }
  }

  article:nth-child(2) {
    .leftWing {
      left: -200px;
      top: 0;
      transform: rotate(${cheersAngleInDegrees}deg);
  
      @media (min-width: 768px) {
        left: -250px;
      }
  
      @media (min-width: 1200px) {
        left: -340px;
      }
    }
  }

  ul {
    list-style: none;

    li {
      margin-left: 0;
    }
  }
`

const Bird = styled.div`
  text-align: center;
  margin: -30px 40px -20px 0;

  img {
    height: 90px;
  }
`;

const Hero = styled.div`
  width: 100%;
  max-height: calc(100vh - 76px);
  position: relative;

  .heroImg {
    .gatsby-image-wrapper, img {
      max-height: calc(100vh - 76px);
    }
  }

  .stamp {
    display: none;
    visibility: hidden;
    transform: scale(.4);
    position: absolute;
    left: -90px;
    bottom: -20px;

    @media (min-width: 768px) {
      display: block;
      visibility: visible;
    }

    @media (min-width: 1200px) {
      transform: scale(.6);
      left: 0;
      bottom: 0;
    }
  }
`

const FullWidthImage = styled.div`
  .gatsby-image-wrapper, img {
    max-height: calc(100vh);
  }
`

const Pricing = ({ location }) => {
  return (
    <Container>
      <Seo title={paths.pricing.title} location={location} />
      <Hero>
          <StaticImage className="heroImg" alt="Seattle-Wedding-Photographer-the-fields-willie-greens-snohomish-outdoor-sunset-portraits-3" src="../images-galleries/portfolios/weddings/Seattle-Wedding-Photographer-the-fields-willie-greens-snohomish-outdoor-sunset-portraits-3.1.jpg" layout="fullWidth" />
          <StaticImage className="stamp" alt="Photography by Betty Elaine Logo" src="../images/logos/Secondary-Logo-black-1000px.png" width={500} style={{ position: "absolute" }} />
      </Hero>
      <PricingList>
        <article>
          <WingedContent content={
            <div>
              <h2>Elopement Pricing</h2>
              <p>Begins at {pricing.elopement.startingPrice}</p>
              <p>Elopement collections include:</p>
              <ul>
                <li>Minimum 4hrs photography coverage</li>
                <li>Only gallery of full res jpegs</li>
                <li>Location assistance</li>
                <li>Photography timeline planning</li>
              </ul>
            </div>
          } rightWingContent={
            <StaticImage alt="Champagne Illustration" src="../images/illustrations/Champagne2-color-large.png" height={500} style={{ position: "absolute" }} />
          } />
        </article>
        <article>
          <WingedContent content={
            <div>
              <h2>Wedding Pricing</h2>
              <p>Begins at {pricing.wedding.startingPrice}</p>
              <p>Wedding collections include:</p>
              <ul>
                <li>Minimum 6hrs photography coverage</li>
                <li>Photography timeline planning</li>
                <li>Only gallery of full res jpegs</li>
              </ul>
            </div>
          } leftWingContent={
            <StaticImage alt="Champagne Illustration" src="../images/illustrations/Champagne2-color-large.png" height={500} style={{ position: "absolute" }} />
          } />
        </article>
        <article>
          <div>
            <h2>Adventure Pricing</h2>
            <p>Seattle Area Begins at {pricing.adventure.seattleStartingPrice}</p> 
            <p>Pacific Northwest &amp; National Parks Begin at {pricing.adventure.nationalParksStartingPrice}</p>
          </div>
        </article>
        <p className="text-center">
          <Link className="pure-button pure-button-primary full-touch-button" to={paths.contact.url}>Book Now</Link>
        </p>
      </PricingList>
      <section>
        <h2>FAQs</h2>

        <article>
          <h3>What is the difference in booking elopements vs wedding collections?</h3>

          <p>While elopements tend to be a smaller guest list (usually around 10 or under), and maybe even just the two of you, that isn't the only difference. Elopement days are focused on the two of you having your best day ever, saying vows in intentional location, surrounded by fewer people. Creating your own unique experience of what a wedding day can be. </p>

          <p>Wedding collections are for events larger than elopements. These weddings focus on the collective experience of your guests, they are an equal part of the experience, location, and events are planned for everyone to witness and take part in. This can be a micro wedding or even a large celebration. </p>
        </article>
        <article>
          <h3>Are Adventures Weddings for Elopements Only?</h3>

          <p>Not at all! Washington State has plenty of beautiful mountain or coastal wedding venues so you can have all your friends and family celebrate with you at your dream location for adventurous wedding photography. Adventure weddings are about celebrating in the outdoors, and experiencing they beauty of the outdoors as you say your vows. Whether you elope just the two of you, have an intimate wedding with your closest friends and family, or have a large celebrations with all your loved ones is your choice! Adventure weddings are perfect for the couple who want a day that celebrates them equally, and want to have a unique ceremony that differs from what the traditional wedding industry tells you your wedding day should look like.</p>
        </article>

        <FullWidthImage>
          <StaticImage alt="Artist-Point-Elopement-NorthCascades-Washington-Adventure-Wedding-Photographer-1" src="../images-galleries/portfolios/weddings/Artist-Point-Elopement-NorthCascades-Washington-Adventure-Wedding-Photographer-19.jpg" layout="fullWidth" />
        </FullWidthImage>

        <article>
          <h3>How many hours will i need for an elopement wedding?</h3>

          <p>Elopements start at a 4hr minimum for booking, and can even grow to 6hrs+ of coverage depending on the timeline for your day. Photography coverage is consecutive, so this will depend on where you are eloping, how many miles on a trail we will be hiking on, if you want to go to multiple locations, if you want getting ready coverage, etc. I am here to advise you on the amount of coverage you need, or to help choose location that fit what you desire for your wedding day, and what you've budgeted for photography coverage.</p>
        </article>
        <article>
          <h3>Can you officiate our wedding? </h3>

          <p>I am an ordained officiant, and I can sign your marriage license. Since I will be photographing your day, I still recommend you find an officiant if you want someone to create and officiate a ceremony for you. But I can absolutely help sign the paperwork and make things official. </p>
        </article>
        <article>
          <h3>We don't want to hike a lot, can we still have an adventure elopement, or elope in the mountains?</h3>

          <p>Absolutely! Maybe your dream is to get married at a stunning location and then go kayaking together, or horseback riding on the beach, or go on a hot air balloon ride to say your vows. Not all elopements mean miles and miles of hiking, and I know plenty of places in the PNW that don't require a full day of hiking to get to. It's one of the reasons I love living here!</p>
        </article>
        <article>
          <h3>What happens if it rains, or if there is dangerous weather?</h3>

          <p>As a professional adventure photographer, I'm confident shooting in dynamic weather conditions. Rain or shine, we will be as prepared as possible to have the best time. Some of my favorite images are created on the rainiest wedding days! In the case of inclement weather, or closures due to forest fires, and other ‘Act of God' outside of our control, it is helpful to have a backup location planned. I will advice and assist you if this should happen.</p>
        </article>

        <p className="text-center">
          <Link className="pure-button pure-button-primary full-touch-button" to={paths.contact.url}>Contact Me</Link>
        </p>
      </section>

      <FullWidthImage>
        <StaticImage alt="Tree-House-Point-Seattle-Elopement-Photographer-Adventure-Weddings-A-1" src="../images-galleries/portfolios/home/10_Tree-House-Point-Elopement-Photographer-Adventure-Weddings-A-1.jpg" layout="fullWidth"/>
      </FullWidthImage>

      {/* Carousel */}
      <section>
        <h2>Reviews</h2>

        <Bird>
          <StaticImage alt="Northwest Towee Illustration" src="../images/illustrations/Towhee-color-medium.png" width={100} />
        </Bird>

        <article>
          <h2>Karen &amp; Dave</h2>
          <p>"I still can't stop looking at the finished products, the pictures are fantastic! We didn't realize we could look so good. All of my friends keep asking me who took the photos and are very impressed. We could not be happier to have worked with Betty! We are both very grateful that she shared her photography experience with us and we would recommend her 100%!"</p>
        </article>
        {/* <article>
          <h2>Eric &amp; Sara</h2>
          <p>"She instils immediate trust and confidence, which translates into unbelievable photos that capture the emotion and excitement in a way we never thought possible. Most importantly, Betty made us feel at ease throughout both our engagement shoot and our wedding. She possesses a calm, reassuring demeanor, and we were very fortunate to have her as a part of our special day. Her eye for small but significant details is incredible, and we have had many friends and family comment that she took some of the best wedding photos they have ever seen – we couldn't agree more!"</p>
        </article>
        <article>
          <h2>Karly &amp; Peter</h2>
          <p>"Betty is a magical wizard! Betty totally blew my expectations out of the water! She was not only was incredibly receptive to our ideas, but enhanced them, providing suggestions and recommendations that would make for truly special images"</p>
        </article>
        <article>
          <h2>Elizabeth &amp; Joel</h2>
          <p>"Betty from day one was not only accommodating, but patient and enthusiastic. Her artistry and talents are apparent in every frame of our wedding day, and her attention to details made me feel confident that she would capture every little thing we wanted to be able to treasure for years to come."</p>
        </article>
        <article>
          <h2>Katie &amp; Sean</h2>
          <p>"I think I looked through our wedding pictures a million times and keep falling in love with them and the day all over again."</p>
        </article>
        <article>
          <h2>Megan &amp; Erik</h2>
          <p>"There is no one else that I would have trusted with our wedding photography. She captured every aspect of our day perfectly."</p>
        </article>
        <article>
          <h2>Julie &amp; John</h2>
          <p>"Betty was absolutely my favorite vendor that we booked for our wedding. I can't imagine sharing our day with anyone else!"</p>
        </article>
        <article>
          <h2>Karen &amp; Dave</h2>
          <p>"I still can't stop looking at the finished products, the pictures are fantastic! We didn't realize we could look so good. All of my friends keep asking me who took the photos and are very impressed. We could not be happier to have worked with Betty! We are both very grateful that she shared her photography experience with us and we would recommend her 100%!"</p>
        </article>
        <article>
          <h2>Eric &amp; Sara</h2>
          <p>"She instils immediate trust and confidence, which translates into unbelievable photos that capture the emotion and excitement in a way we never thought possible. Most importantly, Betty made us feel at ease throughout both our engagement shoot and our wedding. She possesses a calm, reassuring demeanor, and we were very fortunate to have her as a part of our special day. Her eye for small but significant details is incredible, and we have had many friends and family comment that she took some of the best wedding photos they have ever seen – we couldn't agree more!"</p>
        </article> */}
        <p className="text-center">
          <Link className="pure-button pure-button-primary full-touch-button" to={paths.contact.url}>Let's Chat!</Link>
        </p>
      </section>
    </Container >
  )
}

export default Pricing

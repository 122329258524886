const pricing = {
  adventure: {
    seattleStartingPrice: "$1200",
    nationalParksStartingPrice: "$2000"
  },
  elopement: {
    startingPrice: "$4000"
  },
  wedding: {
    startingPrice: "$6000"
  }
};

export default pricing;